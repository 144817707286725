import Vue from 'vue'

class ReportRepository {

  usages(data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/report/usages',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  usagesExport(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/report/usages/export',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  offline(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/report/offline',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  offlineExport(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/report/offline/export',
        method: 'post',
        data,
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new ReportRepository()